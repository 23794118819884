import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from "gatsby";
import get from "lodash/get";
import Seo from "../components/seo";
import { Layout } from "../components/mdxLayout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { IconBackground } from "../components/IconBackground";
export const PaperPreview = ({
  paper
}) => {
  return <Link to={`/whitepaper/${paper.slug}`} className="flex flex-col rounded-lg shadow-lg overflow-hidden" mdxType="Link">
      <div className="flex-shrink-0">
        <GatsbyImage className="h-48 w-full object-cover" image={getImage(paper.heroImage)} alt={paper.title} mdxType="GatsbyImage" />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <a href="#" className="block mt-2">
            <p className="text-xl font-semibold text-gray-900">{paper.title}</p>
            <div className="mt-3 text-base text-gray-700 prose" dangerouslySetInnerHTML={{
            __html: paper.description.childMarkdownRemark.excerpt
          }} />
          </a>
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex space-x-1 text-sm text-gray-500">
            <time dateTime={paper.rawDate}>{paper.publishDate}</time>
          </div>
        </div>
      </div>
    </Link>;
};
export const pageQuery = graphql`
  query WhitepaperIndexQuery {
    allContentfulWhitepaper(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        id
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        rawDate: publishDate
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          childMarkdownRemark {
            html
            excerpt
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  PaperPreview,
  pageQuery,
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Layout title="Whitepapers">
    <div className="bg-grape">
      <div className="relative overflow-hidden">
        <div className="text-white opacity-10">
          <IconBackground />
        </div>
        <div className="relative pb-16 sm:pb-18">
          <div className="mt-16 mx-auto max-w-xl xl:max-w-2xl">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
                Whitepapers
              </h1>
              <h2 className="mt-3 max-w-md mx-auto text-base text-white opacity-50 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Find out whats going on at Trybe and discover whats new
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-white py-12 shadow-overlay">{children}</div>
  </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <section>
  <div className="mx-auto max-w-2xl xl:max-w-3xl p-4 lg:p-0">
    <div className="mt-12 max-w-lg mx-auto grid gap-6 lg:grid-cols-2 lg:max-w-none">
      {props.data.allContentfulWhitepaper.nodes.map(paper => <PaperPreview paper={paper} key={paper.id} mdxType="PaperPreview" />)}
    </div>
  </div>
    </section>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      